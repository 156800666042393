.wwd {
    max-width: 900px;
    margin: 0 auto;
    padding: 100px 30px;
    > h1 {
        font-size: 25px;
        font-weight: 400;
        color: #0098db;
    }
    .container {
        margin-top: 50px;
        display: flex;
        flex-flow: row wrap;
        .item {
            width: 200px;
            height: 200px;
            background-color: #0098DB;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            margin-right: 14px;
            margin-bottom: 14px;
            p {
                font-size: 24px;
                font-weight: 500;
                color: white;
                width: 140px;
            }
        }
    }
}

@media (max-width: 420px) {
    .container {
        align-items: center;
        justify-content: center;
    }
}
.appbar {
    border-bottom: 1px solid #C3C3C3;
}

.container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    max-width: 1300px;
    margin: 0 auto;
    padding: 20px 30px;
}

.logo {
    height: 50px;
}

@media (max-width: 420px) {
    .logo {
        height: 40px;
    }
}
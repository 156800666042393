.intro {
    width: 100%;
    display: flex;
    flex-flow: column;
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 30px;
    img {
        height: 400px;
        max-width: 1300px;
        object-fit: cover;
        object-position: top left;
        margin-left: auto;
    }
}
.header {
    width: 100%;
    padding: 50px 0;
    p {
        max-width: 700px;
        font-size: 25px;
        font-weight: 400;
        color: #acacac;
        b {
            color: black;
            font-weight: 400;
        }
    }
}

@media (max-width: 420px) {
    .intro > img {
        margin-left: -70px;
        max-width: calc(100% + 70px);
    }
}
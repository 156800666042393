.footer {
    background-color: #0098DB;
    .container {
        max-width: 1300px;
        margin: 0 auto;
        padding: 100px 30px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        .contacts {
            h1 {
                font-size: 22px;
                color: white;
                margin-bottom: 10px;
            }
            a {
                display: block;
                text-decoration: none;
                font-size: 18px;
                color: white;
                line-height: 1.5;
            }
        }
        .logo {
            height: 50px;
        }
    }
}

@media (max-width: 420px) {
    .footer .container {
        flex-flow: column;
        .logo {
            margin-left: auto;
            margin-top: 50px;
            height: 40px;
        }
    }
}
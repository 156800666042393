.social_connect {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    margin-bottom: 100px;
    >.image {
        flex: 1;
        max-width: 400px;
        margin-left: auto;
        border-bottom: 1px solid #0098DB;
        img {
            max-width: 100%;
        }
    }
    >.social_information {
        padding-left: 20px;
        padding-bottom: 10px;
        display: flex;
        flex-flow: row nowrap;
        border-bottom: 1px solid #0098DB;
        h1 {
            font-size: 18px;
            line-height: 1;
            margin-bottom: 10px;
        }
        p {
            font-size: 14px;
            line-height: 1.5;
            color: #ACACAC;
        }
        .icon {
            margin-left: 50px;
            margin-top: auto;
            width: 20px;
            height: 20px;
            object-fit: contain;
            object-position: center center;
        }
    }
}

@media (max-width: 600px) {
    .social_connect {
        flex-flow: column;
        >.image {
            border: 0;
            max-width: 100%;
        }
        >.social_information {
            border: 0;
            margin: 50px auto;
        }
    }
}

.partner {
    max-width: 900px;
    margin: 0 auto;
    padding: 100px 30px;
    display: flex;
    flex-flow: row wrap;
    .content {
        flex: 1;
        margin: auto 0;
        h1 {
            font-size: 20px;
            font-weight: 500;
            color: #0098DB;
            margin-bottom: 40px;
        }
        p {
            font-size: 20px;
            color: #ACACAC;
            font-weight: 400;
        }
    }
    .image {
        flex: 1;
        margin-left: 100px;
        img {
            max-width: 100%;
        }
    }
}

@media (max-width: 600px) {
    .partner {
        flex-flow: column;
        .content {
            margin-bottom: 50px;
        }
        .image {
            margin-left: 0;
        }
    }
}
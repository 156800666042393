.clients {
    max-width: 1300px;
    margin: 0 auto;
    padding: 100px 30px;
    h1 {
        font-size: 25px;
        font-weight: 500;
        text-align: center;
        color: #0098DB;
    }
    .row {
        margin-top: 50px;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        > img {
            max-height: 80px;
            margin: 10px;
        }
    }
}

@media (max-width: 420px) {
    .clients .row {
        justify-content: space-around;
    }
}
.button {
    position: relative;
    overflow: hidden;
    background-color: #0098db;
    font-size: 16px;
    border: 0;
    outline: 0;
    cursor: pointer;
    padding: 12px 23px;
    color: white;
    font-weight: 400;
}
.button::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    transform: translateX(-100%);
    transition: all .2s;
}
.button:hover::after {
    transform: translateX(0);
}
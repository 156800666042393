.about {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    padding: 0 30px;
    >h1 {
        max-width: 500px;
        color: #0098DB;
        font-size: 38px;
        font-weight: 500;
        margin-bottom: 50px;
        margin-top: 150px;
    }
    >p {
        max-width: 500px;
        font-size: 20px;
        color: #ACACAC;
        margin-bottom: 150px;
    }
}